import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import { useRecoilState } from 'recoil';
import { subAtom, tokenAtom } from '../core/config/atoms';

import moment from 'moment';
import { toast } from 'react-toastify';

import LayoutPos from '../layouts/LayoutPos';
import LayoutKDS from '../layouts/LayoutKDS';
import LayoutOrder from '../layouts/LayoutOrder';
import LayoutInventory from '../layouts/LayoutInventory';
import LayoutStaff from '../layouts/LayoutStaff';
import LayoutReports from '../layouts/LayoutReports';
import LayoutSetting from '../layouts/LayoutSetting';

import ServicePrinter from 'services/servicePrinter';

import socket from 'core/config/socket';

export default function PageHome() {

    const [token] = useRecoilState(tokenAtom);
    const [sub] = useRecoilState(subAtom);

    const { page } = useParams();

    const printService = new ServicePrinter();

    const getBarCss = () => {
        const givenDate = moment(sub.endDate);
        const currentDate = moment().startOf('day');
        const dayDifference = currentDate.diff(givenDate, 'days') * -1;
        if (dayDifference <= 14) {
            return 'bg-red-600 text-white';
        } else if (dayDifference <= 30) {
            return 'bg-yellow-100 text-yellow-600';
        }
        return 'hidden';
    }

    const getContent = () => {
        const givenDate = moment(sub.endDate);
        const currentDate = moment().startOf('day');
        const dayDifference = currentDate.diff(givenDate, 'days') * -1;
        if (dayDifference <= 14) {
            return <h1>Your subscription is ending in {dayDifference} days. <span className='underline'>Please Renew</span></h1>;
        } else if (dayDifference <= 30) {
            return <h1>Your subscription is ending soon. <span className='underline'>Please Renew</span></h1>;
        }
        return <></>;
    }

    const sendToPrint = (payload, type) => {
        printService.getAllPrinterByType({ 'type': type }, token).then((res) => {
            if (res.status) {
                res.data.map((item) => {
                    if (type.includes("K")) {
                        window.electronAPI.printKOT(payload, item.name, `${item.size}mm`).then((resPrint) => {
                            if (!resPrint.success) {
                                toast.error('Print error.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    } else {
                        window.electronAPI.printBill(payload, item.name, `${item.size}mm`).then((resPrint) => {
                            if (!resPrint.success) {
                                toast.error('Print error.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    }
                });
            }
        });
    }

    const handlePrintKOT = (payload) => {
        console.log(payload);
        sendToPrint(payload, 'K');
    };

    const handlePrintBill = (payload) => {
        console.log(payload);
        sendToPrint(payload, 'B');
    };

    useEffect(() => {
        socket.on('printKOT', handlePrintKOT);
        socket.on('printBill', handlePrintBill);

        return () => {
            socket.off('printKOT', handlePrintKOT);
            socket.off('printBill', handlePrintBill);
        };
    }, []);

    return (
        <div className='w-full h-screen overflow-hidden flex relative'>
            <div className={`h-8 w-[50%] absolute rounded-b-md text-center font-medium top-0 left-[25%] flex items-center justify-center ${getBarCss()}`}>
                {getContent()}
            </div>
            <NavigationBar />
            {(() => {
                switch (page) {
                    case 'pos':
                    case 'posOrder':
                    case 'posBook':
                    case 'posCheckin':
                    case 'posCheckOut':
                        return (<LayoutPos />);
                    case 'kds':
                        return (<LayoutKDS />);
                    case 'order':
                    case 'invoices':
                    case 'booking':
                        return (<LayoutOrder />);
                    case 'inventory':
                    case 'inventoryList':
                    case 'inventoryAdd':
                    case 'raww':
                    case 'rawwAdd':
                    case 'bom':
                    case 'bomAdd':
                        return (<LayoutInventory />);
                    case 'staff':
                    case 'staffAdd':
                    case 'staffEdit':
                        return (<LayoutStaff />);
                    case 'reports':
                    case 'reportItem':
                    case 'reportOrder':
                    case 'reportInvoice':
                    case 'reportBooking':
                        return (<LayoutReports />);
                    case 'setting':
                    case 'subscription':
                    case 'print':
                    case 'company':
                    case 'table':
                    case 'room':
                    case 'roomType':
                    case 'menu':
                    case 'menuAdd':
                    case 'menuEdit':
                        return (<LayoutSetting />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                No Data Found
                            </div>
                        );
                }
            })()}
        </div>
    )
}
